import React from 'react';

const Badge_2 = () => {
    return (
        <div className="neon absolute bottom-4 left-1/2  -translate-x-1/2 ">
            <p className="neon-text">SOLD OUT</p>
        </div>
    );
};

export default Badge_2;
