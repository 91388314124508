import React, { useState, useEffect } from 'react';

const CountdownToDate = ({ targetDate }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    function calculateTimeLeft() {
        const now = new Date();
        const difference = new Date(targetDate) - now;

        if (difference <= 0) {
            return null; // Timpul a expirat
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        return { days, hours, minutes, seconds };
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer); // Curăță intervalul la demontare
    }, [targetDate]);

    if (!timeLeft) {
        return <h1>Timpul a expirat!</h1>;
    }

    return (
        <div>
            <p>
                {timeLeft.days} Days : {timeLeft.hours} Hours{' '}
                {/*  : {timeLeft.minutes} Mim :  {timeLeft.seconds} Sec   */}{' '}
            </p>
        </div>
    );
};

export default CountdownToDate;
