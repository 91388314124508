import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import router, { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { isChildrenPageActive } from '@/utils/daynamicNavigation';
import Logo from '@/public/images/logoanft.png';
import useFirebaseAuth from '@/shared/hooks/useFirebaseAuth';
import CryptoExchangeMenu from '@/sections/header/components/CryptoExchangeMenu';
import { CloseIcon } from '@/shared/components/svgs/CloseIcon';
import { TiktokIcon } from '@/shared/components/svgs/TiktokIcon';
import { InstagramIcon } from '@/shared/components/svgs/InstagramIcon';
import { DiscordIcon } from '@/shared/components/svgs/DiscordIcon';
import { TwitterIcon } from '@/shared/components/svgs/TwitterIcon';
import { FacebookIcon } from '@/shared/components/svgs/FacebookIcon';
import { MenuIcon } from '@/shared/components/svgs/MenuIcon';
//import { CryptoChart } from '../home/components/CryptoChart';
import ProfileDropdownMenu from '@/sections/header/components/ProfileDropdownMenu';
import { AccountContents } from '@/sections/header/components/AccountContentsPhone';
//import { ProfileIcon } from '@/shared/components/svgs/ProfileIcon';
import { useQuery } from 'react-query';
import { getUser } from '@/requests/queries/getUser';
import { signOut, User } from 'firebase/auth';
import { SettingsIcon } from '@/shared/components/svgs/SettingsIcon';
import { LogoutIcon } from '@/shared/components/svgs/LogoutIcon';
import { auth } from '@/shared/firebase/config';
import { useWallet } from '@solana/wallet-adapter-react';
import { socialIcons } from '../footer/config';

export default function Header() {
    const [toggle, setToggle] = useState(false);

    const user = useFirebaseAuth();
    const wallet = useWallet();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1024) {
                setToggle(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    });

    const signOutHandler = async () => {
        await signOut(auth);
        await wallet?.disconnect();
        router.replace('/login');
    };

    const route = useRouter();

    const { data: userData } = useQuery({
        queryKey: ['userData', user],
        queryFn: () => getUser((user as User).uid),
        staleTime: 0,
        enabled: !!user?.uid,
    });

    return (
        <>
            <header className="js-page-header fixed top-0 z-50 w-full backdrop-blur transition-colors pt-30">
                <div className="flex items-center py-5 px-5 xl:px-24 gap-5">
                    <Link className="shrink-0" href="/">
                        <div className="dark:hidden ">
                            {' '}
                            {/* comment out  animate-spin-slow to stop spining*/}
                            <Image
                                src={Logo}
                                className="w-[85px] h-[85px] "
                                sizes="100vw"
                                width="0"
                                height="0"
                                alt="A-NFT Logo"
                            />
                        </div>

                        <div className="hidden dark:block">
                            <Image
                                src={Logo}
                                className="w-[85px] h-[85px]"
                                sizes="100vw"
                                width="0"
                                height="0"
                                alt="A-NFT Logo"
                            />
                        </div>
                    </Link>

                    <div className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 items-center w-full bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
                        <nav className="navbar w-full">
                            <ul className="flex flex-col lg:flex-row">
                                <li className="group" onClick={() => setToggle(false)}>
                                    <Link href="/marketplace">
                                        <button
                                            aria-label="Go to Marketplace"
                                            className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5"
                                        >
                                            <span
                                                className={`flex items-center ${
                                                    isChildrenPageActive('/marketplace', route.asPath)
                                                        ? 'text-accent dark:animate-gradient'
                                                        : ''
                                                }`}
                                            >
                                                Marketplace
                                            </span>
                                        </button>
                                    </Link>
                                </li>

                                {/*  TODO : news section to be solved in the future 
                                <li className="group" onClick={() => setToggle(false)}>
                                    <Link href="/news">
                                        <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                                            <span
                                                className={
                                                    isChildrenPageActive('/news', route.asPath)
                                                        ? 'text-accent dark:animate-gradient'
                                                        : ''
                                                }
                                            >
                                                News
                                            </span>
                                        </button>
                                    </Link>
                                </li>*/}

                                {user?.uid ? (
                                    <li className="group" onClick={() => setToggle(false)}>
                                        <Link href={`/user/${userData?.username}`}>
                                            <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                                                <span
                                                    className={
                                                        isChildrenPageActive('/user/', route.asPath)
                                                            ? 'animate-gradient dark:animate-gradient'
                                                            : ''
                                                    }
                                                >
                                                    My Profile
                                                </span>
                                            </button>
                                        </Link>
                                    </li>
                                ) : null}

                                {user?.uid ? (
                                    <li className="group" onClick={() => setToggle(false)}>
                                        <Link href="/create">
                                            <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                                                <span
                                                    className={
                                                        isChildrenPageActive('/create', route.asPath)
                                                            ? 'animate-gradient dark:animate-gradient'
                                                            : ''
                                                    }
                                                >
                                                    Create
                                                </span>
                                            </button>
                                        </Link>
                                    </li>
                                ) : null}

                                {/*<Link*/}
                                {/*  href={`/user/${userData?.username}`}*/}
                                {/*  className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"*/}
                                {/*>*/}
                                {/*    <ProfileIcon className="fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white" />*/}
                                {/*    <span className="font-display text-jacarta-700 text-sm dark:text-white">My Profile</span>*/}
                                {/*</Link>*/}

                                <li className="group" onClick={() => setToggle(false)}>
                                    <Link href="/help_center">
                                        <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                                            <span
                                                className={
                                                    isChildrenPageActive('/help_center', route.asPath)
                                                        ? 'animate-gradient dark:animate-gradient'
                                                        : ''
                                                }
                                            >
                                                Help
                                            </span>
                                        </button>
                                    </Link>
                                </li>

                                <li className="group" onClick={() => setToggle(false)}>
                                    <Link href="/about">
                                        <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                                            <span
                                                className={
                                                    isChildrenPageActive('/about', route.asPath)
                                                        ? 'animate-gradient dark:animate-gradient'
                                                        : ''
                                                }
                                            >
                                                Team
                                            </span>
                                        </button>
                                    </Link>
                                </li>

                                <li className="group" onClick={() => setToggle(false)}>
                                    <Link href="/contact">
                                        <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                                            <span
                                                className={
                                                    isChildrenPageActive('/contact', route.asPath)
                                                        ? 'animate-gradient dark:animate-gradient'
                                                        : ''
                                                }
                                            >
                                                Contact
                                            </span>
                                        </button>
                                    </Link>
                                </li>

                                <li className="group" onClick={() => setToggle(false)}>
                                    <Link href="https://discord.gg/7PFSgdvkn3">
                                        <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 font-semibold">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5 mr-2"
                                                fill="currentColor"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M12 0C5.372 0 0 5.372 0 12c0 5.302 3.438 9.8 8.207 11.387.6.111.82-.261.82-.58 0-.286-.01-1.04-.016-2.041-3.338.724-4.042-1.611-4.042-1.611-.546-1.387-1.333-1.756-1.333-1.756-1.09-.745.082-.73.082-.73 1.204.084 1.837 1.238 1.837 1.238 1.07 1.833 2.809 1.303 3.494.996.108-.775.419-1.303.762-1.603-2.665-.305-5.466-1.333-5.466-5.933 0-1.31.469-2.383 1.235-3.222-.124-.304-.535-1.528.117-3.184 0 0 1.007-.322 3.3 1.23.957-.266 1.984-.399 3.006-.403 1.022.004 2.05.137 3.008.403 2.291-1.552 3.296-1.23 3.296-1.23.653 1.656.242 2.88.118 3.184.769.839 1.235 1.911 1.235 3.222 0 4.611-2.805 5.624-5.478 5.919.43.372.823 1.104.823 2.225 0 1.606-.015 2.902-.015 3.296 0 .319.218.697.826.58C20.566 21.8 24 17.302 24 12c0-6.628-5.372-12-12-12z" />
                                            </svg>
                                            Join Discord
                                        </button>
                                    </Link>
                                </li>

                                {!user?.uid ? (
                                    <li className="group ml-auto" onClick={() => setToggle(false)}>
                                        <Link href="/login">
                                            <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                                                <span>Register/Login</span>
                                            </button>
                                        </Link>
                                    </li>
                                ) : null}
                            </ul>
                        </nav>

                        <div className="ml-8 gap-20 hidden items-center lg:flex xl:ml-12">
                            {user ? (
                                <>
                                    {/*<HeaderWalletButton />*/}
                                    <CryptoExchangeMenu />
                                </>
                            ) : null}
                            {/*<DarkMode />*/}
                        </div>

                        <div className="ml-2 gap-20 items-center lg:flex xl:ml-2">
                            {user ? (
                                <>
                                    <ProfileDropdownMenu />
                                </>
                            ) : null}
                        </div>
                    </div>

                    <div className="ml-auto flex lg:hidden">
                        <button
                            className="js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
                            aria-label="open mobile menu"
                            onClick={() => setToggle(true)}
                        >
                            <MenuIcon className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white" />
                        </button>
                    </div>
                </div>
            </header>

            {/** phone meniu down */}
            <div
                className={`lg:hidden dark:bg-jacarta-800 invisible fixed inset-0 z-50 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${
                    toggle ? 'nav-menu--is-open' : 'hidden'
                }`}
            >
                <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
                    <Link className="shrink-0" href="/">
                        <div className="dark:hidden">
                            <Image src={Logo} height={85} width={85} alt="A-NFT.World" className="h-auto " />
                        </div>

                        <div className="hidden dark:block">
                            <Image src={Logo} height={85} width={85} alt="A-NFT.World" />
                        </div>
                    </Link>

                    <button
                        className="border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
                        onClick={() => setToggle(false)}
                    >
                        <CloseIcon className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white" />
                    </button>
                </div>

                <div className="relative mt-24 lg:hidden"></div>

                <nav className="navbar w-full">
                    <ul className="flex flex-col lg:flex-row">
                        <li className="group" onClick={() => setToggle(false)}></li>
                    </ul>
                </nav>

                <div className="w-full lg:hidden list-none mt-8">
                    {user ? (
                        <div className="dark:bg-jacarta-800 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform my-4 py-4 border-2 border-jacarta-600">
                            <AccountContents />
                        </div>
                    ) : null}

                    <li className="group" onClick={() => setToggle(false)}>
                        <Link href="/marketplace">
                            <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                                <span
                                    className={
                                        isChildrenPageActive('auction', route.asPath)
                                            ? 'text-accent dark:text-accent'
                                            : ''
                                    }
                                >
                                    Marketplace
                                </span>
                            </button>
                        </Link>
                    </li>

                    {/* TODO : news section to be solved in the future 
                    <li className="group" onClick={() => setToggle(false)}>
                        <Link href="/news">
                            <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                                <span
                                    className={
                                        isChildrenPageActive('/news', route.asPath)
                                            ? 'text-accent dark:text-accent'
                                            : ''
                                    }
                                >
                                    News
                                </span>
                            </button>
                        </Link>
                    </li>*/}

                    {user?.uid ? (
                        <li className="group" onClick={() => setToggle(false)}>
                            <Link href={`/user/${userData?.username}`}>
                                <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                                    <span
                                        className={
                                            isChildrenPageActive('/user/', route.asPath)
                                                ? 'text-accent dark:text-accent'
                                                : ''
                                        }
                                    >
                                        My Profile
                                    </span>
                                </button>
                            </Link>
                        </li>
                    ) : null}

                    {user?.uid ? (
                        <li className="group" onClick={() => setToggle(false)}>
                            <Link href="/create">
                                <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                                    <span
                                        className={
                                            isChildrenPageActive('auction', route.asPath)
                                                ? 'text-accent dark:text-accent'
                                                : ''
                                        }
                                    >
                                        Create
                                    </span>
                                </button>
                            </Link>
                        </li>
                    ) : null}

                    <li className="group" onClick={() => setToggle(false)}>
                        <Link href="/help_center">
                            <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                                <span
                                    className={
                                        isChildrenPageActive('/help_center', route.asPath)
                                            ? 'text-accent dark:text-accent'
                                            : ''
                                    }
                                >
                                    Help
                                </span>
                            </button>
                        </Link>
                    </li>

                    <li className="group" onClick={() => setToggle(false)}>
                        <Link href="/about">
                            <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                                <span
                                    className={
                                        isChildrenPageActive('/about', route.asPath)
                                            ? 'text-accent dark:text-accent'
                                            : ''
                                    }
                                >
                                    About
                                </span>
                            </button>
                        </Link>
                    </li>

                    <li className="group" onClick={() => setToggle(false)}>
                        <Link href="/contact">
                            <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                                <span
                                    className={
                                        isChildrenPageActive('/contact', route.asPath)
                                            ? 'text-accent dark:text-accent'
                                            : ''
                                    }
                                >
                                    Contact
                                </span>
                            </button>
                        </Link>
                    </li>

                    <li className="group" onClick={() => setToggle(false)}>
                        <Link href="https://discord.gg/7PFSgdvkn3">
                            <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 font-semibold">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 mr-2"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M12 0C5.372 0 0 5.372 0 12c0 5.302 3.438 9.8 8.207 11.387.6.111.82-.261.82-.58 0-.286-.01-1.04-.016-2.041-3.338.724-4.042-1.611-4.042-1.611-.546-1.387-1.333-1.756-1.333-1.756-1.09-.745.082-.73.082-.73 1.204.084 1.837 1.238 1.837 1.238 1.07 1.833 2.809 1.303 3.494.996.108-.775.419-1.303.762-1.603-2.665-.305-5.466-1.333-5.466-5.933 0-1.31.469-2.383 1.235-3.222-.124-.304-.535-1.528.117-3.184 0 0 1.007-.322 3.3 1.23.957-.266 1.984-.399 3.006-.403 1.022.004 2.05.137 3.008.403 2.291-1.552 3.296-1.23 3.296-1.23.653 1.656.242 2.88.118 3.184.769.839 1.235 1.911 1.235 3.222 0 4.611-2.805 5.624-5.478 5.919.43.372.823 1.104.823 2.225 0 1.606-.015 2.902-.015 3.296 0 .319.218.697.826.58C20.566 21.8 24 17.302 24 12c0-6.628-5.372-12-12-12z" />
                                </svg>
                                Join Discord
                            </button>
                        </Link>
                    </li>

                    {!user?.uid ? (
                        <li className="group" onClick={() => setToggle(false)}>
                            <Link href="/login">
                                <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                                    <span
                                        className={
                                            isChildrenPageActive('/login', route.asPath)
                                                ? 'text-accent dark:text-accent'
                                                : ''
                                        }
                                    >
                                        Sign in/Sign up
                                    </span>
                                </button>
                            </Link>
                        </li>
                    ) : null}

                    <Link
                        href="/profile/edit"
                        className="dark:hover:bg-jacarta-700 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl py-2 transition-colors"
                    >
                        <span className="font-display text-jacarta-700 dark:text-white">Edit Profile</span>
                    </Link>

                    <button
                        className="w-full dark:hover:bg-jacarta-700 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl py-2 transition-colors"
                        onClick={signOutHandler}
                    >
                        <span className="font-display text-jacarta-700 dark:text-white">Sign out</span>
                    </button>

                    <hr className="dark:bg-jacarta-600 bg-jacarta-100 my-5 h-px border-0" />
                    {/* Social Icons */}
                    <div className="flex items-center justify-center space-x-5">
                        {socialIcons.map((item) => {
                            const { id, href, icon } = item;
                            return (
                                <Link
                                    href={href}
                                    key={id}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="group cursor-pointer"
                                >
                                    {icon}
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}
