import React, { memo, useRef } from 'react';
import { InView } from 'react-intersection-observer';
import { WithInjectedNftMetadata } from '@/shared/components/inject-metadata-hoc/WithInjectedNftMetadata';
import Link from 'next/link';
import { NftSkeletonItem } from '@/shared/components/nfts-grid/NftSkeletonItem';
import { DAS } from 'helius-sdk';
import { AhListedItem } from '@/requests/validation_schemas';
import solanaIconPng from '@/public/images/solana-sol-logo.png';
import Image from 'next/image';
import Badge_1 from '../Badge_1';
import Badge_2 from '../Badge_2';

type NftItemWithCombinedData = DAS.GetAssetResponse & {
    isCollection?: boolean;
    isAhItem?: boolean;
    ahListing?: AhListedItem;
};

interface NftItemProps {
    items?: NftItemWithCombinedData[];
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    rootClassName?: string;
    showSoldStatus?: boolean;
}

const NftItem = memo(
    ({ items, isLoading, isError, rootClassName = 'flex justify-center gap-6 ', showSoldStatus }: NftItemProps) => {
        const itemsRootRef = useRef(null);

        return (
            <div className={rootClassName} ref={itemsRootRef}>
                {isLoading
                    ? Array.from({ length: 12 }, (_, index) => (
                          <div
                              className="rouned-2xl h-full dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg w-[250px]"
                              key={index}
                          >
                              <NftSkeletonItem withText />
                          </div>
                      ))
                    : null}
                {!isLoading && items?.length === 0 && (
                    <div className="text-center my-12 dark:text-white w-full text-xl">No items found</div>
                )}
                {!isLoading &&
                    !isError &&
                    items &&
                    items.map((nftItem) => (
                        <article key={nftItem.id} className="flex flex-col items-center w-[250px]">
                            <figure className="relative">
                                <InView triggerOnce={true}>
                                    {({ inView, ref }) => (
                                        <div ref={ref}>
                                            {inView ? (
                                                <WithInjectedNftMetadata
                                                    ref={itemsRootRef}
                                                    metadataUri={nftItem?.content?.json_uri}
                                                    loadingComponent={<NftSkeletonItem />}
                                                >
                                                    {(metadata) => (
                                                        <>
                                                            <Link href={`/item/${nftItem.id || ''}`}>
                                                                <img
                                                                    width={250}
                                                                    height={250}
                                                                    src={metadata?.image}
                                                                    alt={`Nft name: ${metadata?.name}, Description: ${metadata?.description}`}
                                                                    className="w-full h-[250px] rounded-[0.625rem] object-cover"
                                                                ></img>
                                                            </Link>
                                                            {nftItem.ahListing?.category === 'Memes' ||
                                                            nftItem.ahListing?.category === 'Collectibles' ||
                                                            showSoldStatus === false ? (
                                                                <div>
                                                                    <Badge_2 />
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </WithInjectedNftMetadata>
                                            ) : null}
                                        </div>
                                    )}
                                </InView>
                            </figure>

                            <div className="flex justify-between pt-4 pb-2 w-full">
                                {nftItem.ahListing?.category ? (
                                    <span className="border-jacarta-600 p-2 rounded-lg bg-jacarta-600 border min-w-[5rem] text-center">
                                        <span className="dark:text-white text-accent ">
                                            {nftItem.ahListing?.category}
                                        </span>
                                    </span>
                                ) : null}

                                {/** badges for sold / on sale under picture */}
                                {/* {nftItem.ahListing?.category === 'Memes' || 
                                nftItem.ahListing?.category === 'Collectibles' ||
                                showSoldStatus === false ? (
                                    <div>
                                        <Badge_2 />
                                    </div>
                                ) : (
                                    <div>
                                        <Badge_1 />
                                    </div>
                                )} */}
                            </div>

                            <div className="mt-3 flex items-center justify-between w-full size-10">
                                <Link href={`/item/${nftItem.id || ''}`}>
                                    <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                                        {nftItem?.content?.metadata.name}
                                    </span>
                                </Link>
                                {nftItem.ahListing ? (
                                    <div className="flex items-center text-lg">
                                        <span className="dark:text-white">
                                            {nftItem.ahListing.price / Math.pow(10, 9)}
                                        </span>
                                        <Image
                                            src={solanaIconPng.src}
                                            width={19}
                                            height={19}
                                            className="mx-2"
                                            alt="Solana icon"
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </article>
                    ))}
            </div>
        );
    }
);

export default NftItem;
