import React from 'react';

const Badge_1 = () => {
    return (
        <div className="neon absolute bottom-4 left-1/2  -translate-x-1/2 ">
            <p className="neon-text1">ON SALE</p>
        </div>
    );
};

export default Badge_1;
