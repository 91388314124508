import Image from 'next/image';
import React from 'react';

export const Process = () => {
    return (
        <>
            {/* <!-- Process --> */}
            <section className="relative py-24">
                <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
                    <Image
                        width={1519}
                        height={773}
                        priority
                        src="/images/gradient_light.jpg"
                        alt="gradient"
                        className="h-full w-full object-cover"
                    />
                </picture>

                <div className="container">
                    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
                        {/** first */}
                        <div className="relative rounded-2.5xl border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#8358ff] transition-shadow hover:shadow-[0_16px_24px_-8px_#8358ff] dark:border-jacarta-700 dark:bg-jacarta-700">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                className="absolute right-3 top-3 h-16 w-16 fill-jacarta-50 dark:fill-jacarta-600"
                            >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" />
                            </svg>
                            <div className="mb-6 inline-flex items-center justify-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    className="h-12 w-12 fill-accent"
                                >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" />
                                </svg>
                            </div>
                            <h3 className="mb-4 font-display text-lg text-jacarta-700 dark:text-white">
                                Artisti/Collector Account
                            </h3>
                            <p className="dark:text-jacarta-300">
                                To sign up on A-NFT.World, install or check if you have Phantom Wallet. Then, use your
                                email and wallet to register.
                            </p>
                        </div>
                        {/** secoundo */}
                        <div className="relative rounded-2.5xl border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#eab308] transition-shadow hover:shadow-[0_16px_24px_-8px_#eab308] dark:border-jacarta-700 dark:bg-jacarta-700">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                className="absolute right-3 top-3 h-16 w-16 fill-jacarta-50 dark:fill-jacarta-600"
                            >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
                            </svg>
                            <div className="mb-6 inline-flex items-center justify-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    className="h-12 w-12 fill-current text-yellow-500" //Dragos Bacitea : added enforce yellow transform since fill-yellow is not overwriting
                                >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
                                </svg>
                            </div>

                            <h3 className="mb-4 font-display text-lg text-jacarta-700 dark:text-white">
                                Create Your Collection
                            </h3>
                            <p className="dark:text-jacarta-300">
                                After creating your account, click "Create" to set up your collection. Add a
                                description, profile and banner images, and set a secondary sales fee, add your
                                attributes and create your collection.
                            </p>
                        </div>
                        {/** 3rd */}
                        <div className="relative rounded-2.5xl border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#facc15] transition-shadow hover:shadow-[0_16px_24px_-8px_#facc15] dark:border-jacarta-700 dark:bg-jacarta-700">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                className="absolute right-3 top-3 h-16 w-16 fill-jacarta-50 dark:fill-jacarta-600"
                            >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
                            </svg>
                            <div className="mb-6 inline-flex items-center justify-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    className="h-12 w-12 fill-current text-yellow-400" //Dragos Bacitea : added enforce yellow transform since fill-yellow is not overwriting
                                >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
                                </svg>
                            </div>

                            <h3 className="mb-4 font-display text-lg text-jacarta-700 dark:text-white">
                                Add Your NFTs
                            </h3>
                            <p className="dark:text-jacarta-300">
                                Upload your work (image, video, audio, or 3D art), add a title and description, and
                                customize your NFTs with properties, stats, type.
                            </p>
                        </div>
                        {/** 4rd */}
                        <div className="relative rounded-2.5xl border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#10b981] transition-shadow hover:shadow-[0_16px_24px_-8px_#10b981] dark:border-jacarta-700 dark:bg-jacarta-700">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                className="absolute right-3 top-3 h-16 w-16 fill-jacarta-50 dark:fill-jacarta-600"
                            >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M10.9 2.1l9.899 1.415 1.414 9.9-9.192 9.192a1 1 0 0 1-1.414 0l-9.9-9.9a1 1 0 0 1 0-1.414L10.9 2.1zm2.828 8.486a2 2 0 1 0 2.828-2.829 2 2 0 0 0-2.828 2.829z" />
                            </svg>
                            <div className="mb-6 inline-flex items-center justify-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    className="h-12 w-12 fill-green"
                                >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M10.9 2.1l9.899 1.415 1.414 9.9-9.192 9.192a1 1 0 0 1-1.414 0l-9.9-9.9a1 1 0 0 1 0-1.414L10.9 2.1zm2.828 8.486a2 2 0 1 0 2.828-2.829 2 2 0 0 0-2.828 2.829z" />
                                </svg>
                            </div>

                            <h3 className="mb-4 font-display text-lg text-jacarta-700 dark:text-white">
                                List Them For Sale
                            </h3>
                            <p className="dark:text-jacarta-300">
                                Feature Available : fixed-price listings, upcoming auctions, and declining-price
                                listings. You choose how you want tosell your NFTs!
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- end process --> */}
        </>
    );
};
