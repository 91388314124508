import React, { memo, useRef } from 'react';
import { InView } from 'react-intersection-observer';
import { WithInjectedNftMetadata } from '@/shared/components/inject-metadata-hoc/WithInjectedNftMetadata';
import Link from 'next/link';
import { NftSkeletonItem } from '@/shared/components/nfts-grid/NftSkeletonItem';
import { DAS } from 'helius-sdk';
import { AhListedItem } from '@/requests/validation_schemas';
import solanaIconPng from '@/public/images/solana-sol-logo.png';
import Image from 'next/image';
import Badge_1 from '../Badge_1';
import Badge_2 from '../Badge_2';
import NftItem from './NftItem';

type NftItemWithCombinedData = DAS.GetAssetResponse & {
    isCollection?: boolean;
    isAhItem?: boolean;
    ahListing?: AhListedItem;
};
interface NftGridProps {
    items?: NftItemWithCombinedData[];
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    rootClassName?: string;
    showSoldStatus?: boolean;
}

const NftsGrid = memo(
    ({
        items,
        isLoading,
        isError,
        rootClassName = 'grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4',
        showSoldStatus,
    }: NftGridProps) => {
        // console.log({ isLoading });
        const itemsRootRef = useRef(null);

        return (
            <div className={rootClassName} ref={itemsRootRef}>
                {isLoading
                    ? Array.from({ length: 12 }, (_, index) => {
                          return (
                              <div
                                  className="rouned-2xl h-full dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg"
                                  key={index}
                              >
                                  <NftSkeletonItem withText />
                              </div>
                          );
                      })
                    : null}
                {!isLoading && items?.length === 0 && (
                    <div className="text-center my-12 dark:text-white w-full col-span-2 text-xl">No items found</div>
                )}
                {!isLoading &&
                    !isError &&
                    items &&
                    items.map((nftItem) => {
                        // console.log({ nftItem: nftItem.ahListing });
                        return (
                            <article key={nftItem.id}>
                                <div>
                                    <figure className="relative">
                                        {nftItem.ahListing?.category === 'Memes' ||
                                        nftItem.ahListing?.category === 'Collectibles' ||
                                        showSoldStatus === false ? ( // de verificat confitia de hasSold - KFC {nftItem.ahListing?.hasSold === true}
                                            <div className="h-full dark:bg-jacarta-700 dark:shadow-[0px_0px_10px_4px_rgba(200,0,100,1)] rounded-2.5xl border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg rounded-2xl">
                                                <NftItem
                                                    items={[nftItem]}
                                                    isLoading={false}
                                                    isSuccess={true}
                                                    isError={false}
                                                    showSoldStatus={showSoldStatus}
                                                />
                                            </div>
                                        ) : (
                                            <div className="h-full dark:bg-jacarta-700 dark:shadow-[0px_0px_10px_4px_rgba(0,200,100,1)] rounded-2.5xl border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg rounded-2xl">
                                                <NftItem
                                                    items={[nftItem]}
                                                    isLoading={false}
                                                    isSuccess={true}
                                                    isError={false}
                                                    showSoldStatus={showSoldStatus}
                                                />
                                            </div>
                                        )}
                                    </figure>
                                </div>
                            </article>
                        );
                    })}
            </div>
        );
    }
);

export default NftsGrid;
