import Hero from '@/sections/home/components/Hero';
import NewsLetter from '@/sections/home/components/NewsLetter';
import React from 'react';
//import { CryptoChart } from '@/sections/home/components/CryptoChart';
//import { HomeSlider } from '@/sections/home/components/HomeSlider';
import { AuctionSection } from '@/sections/marketplace/AuctionSection';
import HeadLine from '../marketplace/components/HeadLine';
import { CryptoChart } from './components/CryptoCarusel';

export const HomeSection = () => {
    return (
        <div className="flex-auto">
            <video autoPlay loop muted className="video-background">
                <source src="/images/BackGroundVideo/BG_video.mp4" type="video/mp4" />
            </video>
            <div>
                <Hero />
            </div>

            {/*<div>{ make a small line of cripto charts/}</div>

            {/<HomeSlider /> Dragos Note : eliminating this slider till we will update it with new artwork /}

            
            {/ <CryptoChart /> */}
            {/* <div>
                <CryptoChart />
            </div> */}

            <div>
                <NewsLetter />
            </div>

            <div>
                <HeadLine
                    image="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/26a1.png"
                    text="Checkout Trending NFTs"
                    classes="mb-8 text-center font-display text-3xl text-jacarta-700 dark:text-white"
                    pera={undefined}
                />
                <AuctionSection limit={10} />
            </div>
            <div>
                <p className="text-jacarta-700 mx-auto max-w-2xl text-center text-lg dark:text-white">
                    Join our whitelisting and to stay in the loop with our newest feature releases, NFT drops, and tips
                    and tricks for navigating A-NFT.World
                </p>

                <div className="mx-auto mt-7 max-w-md text-center">
                    <form className="relative" onSubmit={(e) => e.preventDefault()}>
                        <button className="w-auto mb-12 text-xl rounded-full animate-gradient py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume">
                            <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=zG8U6vUZs0ajGCV4dOtk5BGCCLdpPIJHsU3ROtLCro9UQ0tBWDI3UFc3NDlHQ0xHQktZVFRMQ0YzVy4u">
                                Join our whitelisting
                            </a>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};
