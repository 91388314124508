import React from 'react';
import Image from 'next/image';
import { Heading } from '@/shared/components/heading/Heading';
//import { newseLatterData } from '@/sections/home/config';
import { Process } from './process';

const NewsLetter = () => {
    return (
        <section className=" relative py-24">
            <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
                <Image
                    src="/images/gradient_light.jpg"
                    alt="gradient"
                    className="h-full w-full object-cover"
                    width={1559}
                    height={761}
                />
            </picture>

            <div className="container">
                <Heading
                    text="Ready to create and sell your NFTs?"
                    classes="font-display text-jacarta-700 mb-16 text-center text-3xl dark:text-white"
                />

                <Process></Process>
            </div>
        </section>
    );
};

export default NewsLetter;
